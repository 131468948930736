import { CargoUnit, CargoUnitHitProps } from "types/cargoUnitTypes";
import { TrackingRecord, TripHitProps } from "types/trackingTypes";
import { formatDate } from "./date";

export function sanitizeAlgoliaTrips(algoliaRecords: TripHitProps[]): TrackingRecord[] {
  const records = [];
  for (const record of algoliaRecords) {
    const { tripData } = record;

    // skip if tripData is undefined
    if (!tripData) {
      continue;
    }

    records.push({
      ...tripData,
      currentEta: tripData.currentEta && new Date(tripData.currentEta),
      originalEta: tripData.originalEta && new Date(tripData.originalEta),
      lastTrackingUpdate: new Date(tripData.lastTrackingUpdate),
      legs: tripData.legs.map((leg) => ({
        ...leg,
        lastTrackingUpdate: new Date(leg.lastTrackingUpdate),
        transportCurrentEta: leg.transportCurrentEta && new Date(leg.transportCurrentEta),
        transportOriginalEta: leg.transportOriginalEta && new Date(leg.transportOriginalEta),
        currentEta: leg.currentEta && new Date(leg.currentEta),
        originalEta: leg.originalEta && new Date(leg.originalEta),
      })),
    });
  }

  return records;
}

export function sanitizeAlgoliaCargoUnits(
  algoliaRecords: CargoUnitHitProps[],
  language: string,
): CargoUnit[] {
  const records: CargoUnit[] = [];

  for (const record of algoliaRecords) {
    records.push({
      ...record,
      id: record.objectID,
      entryBLNumbers: record.entryBLNumbers?.join(", "),
      endpointBLNumbers: record.endpointBLNumbers?.join(", "),
      purchaseOrderNumbers: record.purchaseOrderNumbers?.join(", "),
      invoiceNumbers: record.invoiceNumbers?.join(", "),
      departureDate: record.departureDate
        ? formatDate(new Date(record.departureDate), language)
        : undefined,
      entryOriginalETA: record.entryOriginalETA
        ? formatDate(new Date(record.entryOriginalETA), language)
        : undefined,
      entryCurrentETA: record.entryCurrentETA
        ? formatDate(new Date(record.entryCurrentETA), language)
        : undefined,
      entryATA: record.entryATA ? formatDate(new Date(record.entryATA), language) : undefined,
      customsReleaseETA: record.customsReleaseETA
        ? formatDate(new Date(record.customsReleaseETA), language)
        : undefined,
      customsReleaseATA: record.customsReleaseATA
        ? formatDate(new Date(record.customsReleaseATA), language)
        : undefined,
      endpointOriginalETA: record.endpointOriginalETA
        ? formatDate(new Date(record.endpointOriginalETA), language)
        : undefined,
      endpointCurrentETA: record.endpointCurrentETA
        ? formatDate(new Date(record.endpointCurrentETA), language)
        : undefined,
      endpointATA: record.endpointATA
        ? formatDate(new Date(record.endpointATA), language)
        : undefined,
      containerReturnDate: record.containerReturnDate
        ? formatDate(new Date(record.containerReturnDate), language)
        : undefined,
    });
  }

  return records;
}
