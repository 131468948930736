import { useCallback, useMemo, useRef, useState } from "react";
import {
  ColumnSizingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  OnChangeFn,
  useReactTable,
} from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useCargoUnitStore } from "store/cargoUnitStore";
import { WithLoggedInAuthInfoProps, withAuthInfo } from "services/auth";
import { getTenantInfo } from "services/api/config";
import { CargoUnit } from "types/cargoUnitTypes";
import { cargoUnitViewHeaders } from "features/CargoUnit/constants/tableData";
import { TableCell, TableHeader, CargoUnitViewTable, TableRow } from "./CargoUnitTable.styled";
import { FeatureFlagNames, useFeatureFlags } from "hooks/useFeatureFlags";

type CargoUnitTableParams = {
  cargoUnitData: CargoUnit[];
} & WithLoggedInAuthInfoProps;

const CargoUnitTable = withAuthInfo(({ accessToken, cargoUnitData }: CargoUnitTableParams) => {
  const featureFlags = useFeatureFlags(accessToken);
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<CargoUnit>();
  const columnSizerRef = useRef<Record<string, HTMLElement | null>>({});
  const { cargoUnitId: activeCargoUnitId, setCargoUnitId: setActiveCargoUnitId } =
    useCargoUnitStore();

  const isCargoUnitCardEnabled = featureFlags[FeatureFlagNames.EnableCargoUnitCardFrontend];

  const { data: tenantInfo } = useQuery(["tenantInfo", accessToken], () =>
    getTenantInfo(accessToken),
  );

  const columnSchema = useMemo(() => {
    let tableHeaders = cargoUnitViewHeaders.map(({ key, header }) => {
      return columnHelper.accessor(key, {
        id: key,
        header: t(header) as string,
      });
    });

    if (!tenantInfo?.usesDivisions) {
      tableHeaders = tableHeaders.filter((header) => header.id !== "division");
    }
    return tableHeaders;
  }, [columnHelper, t, tenantInfo]);

  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(() => {
    const resultingState: ColumnSizingState = {};
    for (const { id, size } of columnSchema) {
      if (id && size) {
        resultingState[`${id}`] = size;
      }
    }
    return resultingState;
  });

  const handleOnColumnSizingChange: OnChangeFn<ColumnSizingState> = useCallback((value) => {
    setColumnSizing((state) => {
      return {
        ...state,
        ...(typeof value === "function" ? value?.(state) : value),
      };
    });
  }, []);

  const table = useReactTable({
    data: cargoUnitData,
    columns: columnSchema,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
    defaultColumn: {
      size: 120,
      minSize: 50,
    },
    initialState: {
      columnPinning: {
        left: ["cargoId", "currentModeOfTransport"],
        right: [],
      },
      columnVisibility: {
        id: false,
      },
    },
    onColumnSizingChange: handleOnColumnSizingChange,
    state: {
      columnSizing,
    },
  });

  function openCargoUnitCard(cargoUnitId: string) {
    // Prevent opening card when feature flag is disabled
    if (!isCargoUnitCardEnabled) return;

    // Prevent opening card when text is selected
    const selectedText = window.getSelection()?.toString();
    if (selectedText) return;

    setActiveCargoUnitId(cargoUnitId);
  }

  return (
    <div data-testid="cargo-unit-table" style={{ overflowX: "scroll" }}>
      <CargoUnitViewTable tableWidth={table.getTotalSize()}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader
                  key={header.id}
                  columnWidth={header.column.getSize()}
                  columnStart={header.column.getStart("left")}
                  ref={(element) => {
                    columnSizerRef.current[header.id] = element;
                  }}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  <div
                    className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""}`}
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                  />
                </TableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => openCargoUnitCard(row.getValue("id"))}
              className={
                isCargoUnitCardEnabled && activeCargoUnitId === row.getValue("id") ? "active" : ""
              }
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  columnWidth={cell.column.getSize()}
                  columnStart={cell.column.getStart("left")}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </CargoUnitViewTable>
    </div>
  );
});

export default CargoUnitTable;
