import algoliasearch from "algoliasearch/lite";
import { useQuery } from "@tanstack/react-query";
import { InstantSearch } from "react-instantsearch";
import { getAlgoliaSearchAPIKey } from "services/api/search";
import { getSavedFilter } from "services/api/filters";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import config from "config";

const { ALGOLIA_APP_ID } = config.envs;

type AlgoliaSearchWrapperProps = {
  children: React.ReactNode;
} & WithLoggedInAuthInfoProps;

const AlgoliaSearchWrapper = withAuthInfo(
  ({ accessToken, children }: AlgoliaSearchWrapperProps) => {
    const {
      data: savedQueryString,
      isLoading: savedQueryStringIsLoading,
      isError: savedQueryStringIsError,
    } = useQuery({
      queryFn: () => getSavedFilter(accessToken),
      queryKey: ["getSavedQueryString"],
    });

    const {
      data: algoliasSearchAPIKey,
      isLoading: algoliasSearchAPIKeyIsLoading,
      isError: algoliasSearchAPIKeyIsError,
    } = useQuery({
      queryFn: () => getAlgoliaSearchAPIKey(accessToken),
      queryKey: ["algoliasSearchAPIKey"],
    });

    if (
      algoliasSearchAPIKeyIsLoading ||
      algoliasSearchAPIKeyIsError ||
      savedQueryStringIsLoading ||
      savedQueryStringIsError
    ) {
      return null;
    }

    const searchClient = algoliasearch(ALGOLIA_APP_ID, algoliasSearchAPIKey);

    return (
      <InstantSearch
        searchClient={searchClient}
        routing={true}
        initialUiState={JSON.parse((savedQueryString?.data as string) || "{}")}
      >
        {children}
      </InstantSearch>
    );
  },
);

export default AlgoliaSearchWrapper;
