import { useQuery } from "@tanstack/react-query";
import { WithLoggedInAuthInfoProps, withAuthInfo } from "@propelauth/react";
import { isTokenExpired } from "utils/jwt";
import { getMetabaseToken } from "services/api/analytics";
import { useActiveTenantStore } from "store/activeTenantStore";
import { getCookie, setCookie } from "utils/cookies";

const fetchToken = async (accessToken: string, dashboardId: number, activeTenant: string) => {
  const token = await getMetabaseToken(accessToken, dashboardId);
  setCookie(`${activeTenant}-metabaseToken`, token);
  return token;
};

type MetabaseDashboardProps = WithLoggedInAuthInfoProps & {
  dashboardId: number;
};

const MetabaseDashboard = withAuthInfo(({ accessToken, dashboardId }: MetabaseDashboardProps) => {
  const { activeTenant } = useActiveTenantStore();

  const getToken = async () => {
    const storedToken = getCookie(`${activeTenant}-metabaseToken`);
    if (!storedToken || isTokenExpired(storedToken)) {
      return await fetchToken(accessToken, dashboardId, activeTenant);
    }
    return storedToken;
  };

  const { data: metabaseToken } = useQuery({
    queryFn: getToken,
    queryKey: ["metabaseToken", dashboardId, activeTenant],
  });

  const url = metabaseToken
    ? `https://auba.metabaseapp.com/embed/dashboard/${metabaseToken}#bordered=false&titled=false`
    : "";

  return <iframe src={url} title="Metabase Dashboard" />;
});

export default MetabaseDashboard;
