import { useTranslation } from "react-i18next";
import { Panel, BasicTableInfo, ContainerContents, Milestones } from "./GeneralPanel.styled";
import ContainerIcon from "assets/icons/v2/container_icon.svg";
import MilestoneCompleteIcon from "assets/icons/v2/milestone_complete.svg";
import MilestoneNotStartedIcon from "assets/icons/v2/milestone_not_started.svg";

const GeneralPanel = () => {
  const { t } = useTranslation();

  // TODO: Inject data from API and remove hardcoded data

  return (
    <Panel>
      <BasicTableInfo>
        <thead>
          <th>{t("Vendor")}</th>
          <th>{t("Bill of lading")}</th>
          <th>{t("Purchase Orders")}</th>
        </thead>
        <tbody>
          <tr>
            <td>Transitional Information Long</td>
            <td>68900450984420892634</td>
            <td>MID12345 | MID3456</td>
          </tr>
        </tbody>
      </BasicTableInfo>
      <ContainerContents>
        <h3>Container Contents</h3>
        <div className="content">
          <div className="container-title">
            <img src={ContainerIcon} alt="" />
            <span>BICU 789012 8</span>
          </div>
          <ul className="items">
            <li>Wh. Clip TCH12345T5869300U12345(TCH12345)</li>
            <li>Wh. Clip TCH12345T5869300U12345(TCH12345)</li>
            <li>Wh. Clip TCH12345T5869300U12345(TCH12345)</li>
            <li>Wh. Clip TCH12345T5869300U12345(TCH12345)</li>
            <li>Wh. Clip TCH12345T5869300U12345(TCH12345)</li>
            <li>Wh. Clip TCH12345T5869300U12345(TCH12345)</li>
            <li>Wh. Clip TCH12345T5869300U12345(TCH12345)</li>
          </ul>
          <div className="footer" />
        </div>
      </ContainerContents>
      <Milestones>
        <h3>Milestones</h3>
        <div className="content">
          <div className="row">
            <div className="column milestone">
              <div className="ata">
                <div className="item mileStoneStatus">
                  <img src={MilestoneCompleteIcon} />
                </div>
                <div className="item">ETA: 2021-09-30</div>
                <div className="item">Arrival at Port</div>
              </div>
            </div>
            <div className="column milestone">
              <div className="ata">
                <div className="item mileStoneStatus">
                  <img src={MilestoneCompleteIcon} />
                </div>
                <div className="item">ETA: 2021-09-30</div>
                <div className="item">Arrival at Port</div>
              </div>
            </div>
            <div className="column milestone">
              <div className="ata">
                <div className="item mileStoneStatus">
                  <img src={MilestoneCompleteIcon} />
                </div>
                <div className="item">ETA: 2021-09-30</div>
                <div className="item">Arrival at Port</div>
              </div>
            </div>
            <div className="column milestone">
              <div className="eta">
                <div className="item mileStoneStatus">
                  <img src={MilestoneNotStartedIcon} />
                </div>
                <div className="item">ETA: 2021-09-30</div>
                <div className="item">Arrival at Port</div>
              </div>
            </div>
          </div>
        </div>
      </Milestones>
    </Panel>
  );
};

export default GeneralPanel;
