import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRefinementList } from "react-instantsearch";
import { useQuery } from "@tanstack/react-query";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import FilterButton from "features/Tracking/components/FilterButton/FilterButton";
import { getTenantInfo } from "services/api/config";
import { filtersAttributes } from "../../constants/filters";
import {
  ButtonWrapper,
  Dropdown,
  DropdownSection,
  FilterCategoryButton,
  FilterCategorySection,
} from "features/Tracking/components/FilterDropdown/FilterDropdown.styled";
import FilterIcon from "assets/icons/filter.svg";
import ArrowRight from "assets/icons/arrow_right_icon.svg";
import config from "config";

const { USE_BACKEND_RECORDS } = config.envs;

type FilterCategoryProps = {
  attribute: string;
  display: string;
};

const FilterCategory = ({ attribute, display }: FilterCategoryProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { items, refine } = useRefinementList({ attribute, sortBy: [] });

  return (
    <>
      <FilterCategoryButton
        onClick={() => setOpen(!open)}
        className={"categoryButton " + (open ? "active" : "")}
      >
        {t(display)}
        <img src={ArrowRight} alt={open ? "Close" : "Open"} className={open ? "open" : ""} />
      </FilterCategoryButton>
      {open && (
        <FilterCategorySection>
          {items.length ? (
            items.map((item, i) => (
              <FilterButton
                key={item.label + i}
                text={item.label}
                active={item.isRefined}
                onClick={() => {
                  refine(item.value);
                }}
              />
            ))
          ) : (
            <div>{t("No options available")}</div>
          )}
        </FilterCategorySection>
      )}
    </>
  );
};

const FilterDropdown = withAuthInfo(({ accessToken }: WithLoggedInAuthInfoProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { data: tenantInfo } = useQuery(["tenantInfo", accessToken], () =>
    getTenantInfo(accessToken),
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredAttributes = useMemo(() => {
    if (!tenantInfo) return filtersAttributes;
    return tenantInfo.usesDivisions
      ? filtersAttributes
      : filtersAttributes.filter(({ attribute }) => attribute !== "division");
  }, [tenantInfo]);

  return (
    <ButtonWrapper ref={tooltipRef} className="wrapper">
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        disabled={USE_BACKEND_RECORDS === "true"}
      >
        <img src={FilterIcon} alt="Filter" />
        <span>{t("Filter")}</span>
      </button>
      <Dropdown showDropdown={showDropdown}>
        <DropdownSection>
          <div className="sectionTitle">{t("Add Filter To Cargo Units")}</div>
          <div className="sectionContent">
            {filteredAttributes.map(({ attribute, display }) => (
              <FilterCategory key={attribute} attribute={attribute} display={display} />
            ))}
          </div>
        </DropdownSection>
      </Dropdown>
    </ButtonWrapper>
  );
});

export default FilterDropdown;
