import { CargoUnit } from "types/cargoUnitTypes";

export const cargoUnitViewHeaders: { key: keyof CargoUnit; header: string }[] = [
  { key: "cargoId", header: "Cargo Number" },
  { key: "currentModeOfTransport", header: "Mode of Transportation" },
  { key: "division", header: "Division" },
  { key: "purchaseOrderNumbers", header: "Purchase Order" },
  { key: "invoiceNumbers", header: "Invoice Number" },
  { key: "vendor", header: "Vendor" },
  { key: "departureCountry", header: "Departure Country" },
  { key: "departureCity", header: "Departure City" },
  { key: "endpointCountry", header: "Endpoint Country" },
  { key: "endpointCity", header: "Endpoint City" },
  { key: "departureDate", header: "Departure Date" },
  { key: "entryCity", header: "Entry City" },
  { key: "entryBLNumbers", header: "Entry BL" },
  { key: "entryCarrier", header: "Entry Carrier" },
  { key: "entryModeOfTransport", header: "Entry Mode of Transport" },
  { key: "entryOriginalETA", header: "Entry Original ETA" },
  { key: "entryCurrentETA", header: "Entry Current ETA" },
  { key: "entryATA", header: "Entry ATA" },
  { key: "customsDocumentNumber", header: "Customs Document Number" },
  { key: "customsReleaseETA", header: "Customs Release ETA" },
  { key: "customsReleaseATA", header: "Customs Release ATA" },
  { key: "customsBroker", header: "Customs Broker" },
  { key: "endpointBLNumbers", header: "Endpoint BL" },
  { key: "endpointCarrier", header: "Endpoint Carrier" },
  { key: "endpointOriginalETA", header: "Endpoint Original ETA" },
  { key: "endpointCurrentETA", header: "Endpoint Current ETA" },
  { key: "endpointATA", header: "Endpoint ATA" },
  { key: "daysWithoutDelay", header: "Days Without Delay" },
  { key: "containerReturnDate", header: "Container Return Date" },
  { key: "alertCount", header: "Alerts" },
  { key: "id", header: "ID" },
];
