interface FilterAttribute {
  attribute: string;
  display: string;
}

export const attributesDisplay: Record<string, string> = {
  division: "Division",
  vendor: "Vendor",
  departureCountry: "Departure Country",
  departureCity: "Departure City",
  endpointCountry: "Endpoint Country",
  endpointCity: "Endpoint City",
  entryCity: "Entry City",
  entryCarrier: "Entry Carrier",
  customsBroker: "Customs Broker",
  endpointCarrier: "Endpoint Carrier",
} as const;

export const filtersAttributes: FilterAttribute[] = [
  { attribute: "division", display: attributesDisplay.division },
  { attribute: "vendor", display: attributesDisplay.vendor },
  { attribute: "departureCountry", display: attributesDisplay.departureCountry },
  { attribute: "departureCity", display: attributesDisplay.departureCity },
  { attribute: "endpointCountry", display: attributesDisplay.endpointCountry },
  { attribute: "endpointCity", display: attributesDisplay.endpointCity },
  { attribute: "entryCity", display: attributesDisplay.entryCity },
  { attribute: "entryCarrier", display: attributesDisplay.entryCarrier },
  { attribute: "customsBroker", display: attributesDisplay.customsBroker },
  { attribute: "endpointCarrier", display: attributesDisplay.endpointCarrier },
];
