import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import config from "config";
import * as amplitude from "@amplitude/analytics-browser";
import { Navigate, Route, Routes } from "react-router-dom";
import { withAuthInfo } from "services/auth";
import "assets/stylesheets/App.css";
import LandingPage from "pages/LandingPage";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Tracking from "pages/Tracking";
import RailTracking from "pages/RailTracking";
import EmailIngestion from "pages/EmailIngestion";
import CargoUnitView from "pages/CargoUnitView";
import Layout from "components/layouts/Layout";
import paths from "constants/paths";
import CompleteEmailIngestion from "pages/CompleteEmailIngestion";
import Insights from "pages/Insights";
import LogoutRedirect from "pages/LogoutRedirect";
import KpisPage from "pages/KpisPage";
import { getTenantInfo } from "services/api/config";
import { FeatureFlagNames, useFeatureFlags } from "hooks/useFeatureFlags";
const { RAIL_TENANT_ID } = config.envs;
const REGISTER_RAIL_EVENTS_ROUTE = false;

const App = withAuthInfo(({ accessToken, isLoggedIn, user }) => {
  useEffect(() => {
    if (
      config.envs.AMPLITUDE_DISABLE_FOR_INTERNAL_USERS === "true" &&
      user &&
      /auba\.ai$/.test(user?.email)
    ) {
      // eslint-disable-next-line no-console
      console.log("[amplitude]", "Disabling for internal user");
      return;
    }

    config.envs.AMPLITUDE_API_KEY &&
      amplitude.init(config.envs.AMPLITUDE_API_KEY, {
        userId: user?.userId || "",
        defaultTracking: true,
      });
  }, [user]);
  const featureFlags = useFeatureFlags(accessToken);

  let registerRailEventsRoute = REGISTER_RAIL_EVENTS_ROUTE;
  if (accessToken) {
    const { data: tenantInfo } = useQuery(
      ["tenantInfo", accessToken],
      () => getTenantInfo(accessToken),
      {
        enabled: true,
      },
    );
    if (tenantInfo?.id === RAIL_TENANT_ID) {
      registerRailEventsRoute = true;
    }
  }

  const defaultRoute = featureFlags[FeatureFlagNames.EnableTripsFrontend]
    ? paths.tracking
    : featureFlags[FeatureFlagNames.EnableCargoUnitFrontend]
      ? paths.cargoUnit
      : paths.insights;

  return (
    <Routes>
      {isLoggedIn ? (
        <>
          <Route path={paths.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path="/" element={<Layout />}>
            {featureFlags[FeatureFlagNames.EnableETALogsFrontend] ? (
              <Route path={paths.kpis} element={<KpisPage />} />
            ) : (
              <Route path={paths.kpis} element={<Navigate to={defaultRoute} />} />
            )}
            <Route index element={<Navigate to={defaultRoute} />} />
            {featureFlags[FeatureFlagNames.EnableTripsFrontend] ? (
              <Route path={paths.tracking} element={<Tracking />} />
            ) : (
              <Route path={paths.tracking} element={<Navigate to={defaultRoute} />} />
            )}
            <Route
              path={paths.emailIngestion}
              element={<EmailIngestion choices={[{ name: "Outlook" }, { name: "Gmail" }]} />}
            />
            <Route path={paths.completeAuthorization} element={<CompleteEmailIngestion />} />
            <Route path={paths.insights} element={<Insights />} />
            {registerRailEventsRoute && (
              <Route path={paths.railTracking} element={<RailTracking />} />
            )}
            {featureFlags[FeatureFlagNames.EnableCargoUnitFrontend] ? (
              <Route path={paths.cargoUnit} element={<CargoUnitView />} />
            ) : (
              <Route path={paths.cargoUnit} element={<Navigate to={defaultRoute} />} />
            )}

            {/* ADD HERE MORE PROTECTED ROUTES */}
          </Route>
          {/* TODO: handle this more gracefully? */}
          <Route path="*" element={<Navigate to={defaultRoute} />} />
        </>
      ) : (
        <>
          <Route path={paths.privacyPolicy} element={<PrivacyPolicy />} />
          {/* We have configured Propel Auth to redirect users here after logout */}
          <Route path="/logout-redirect" element={<LogoutRedirect />} />
          <Route path="*" element={<LandingPage />} />
        </>
      )}
    </Routes>
  );
});

export default App;
