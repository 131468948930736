import styled from "@emotion/styled";

export const CargoUnitViewTable = styled.table<{ tableWidth: number }>`
  width: ${(props) => props.tableWidth}px;
  border-collapse: separate;
  margin-top: 20px;
  font-size: 15px;
  border-spacing: 0;
  table-layout: fixed;

  thead {
    position: sticky;
    z-index: 2;
    background-color: white;
    top: 0;
  }
`;

export const TableHeader = styled.th<{ columnWidth: number; columnStart: number }>`
  width: ${(props) => props.columnWidth}px;
  position: relative;
  border-bottom: 1px solid #000000;
  border-right: 1px solid #e9e9ee;
  border-left: 1px solid #e9e9ee;
  padding: 5px;
  overflow: hidden;
  z-index: auto;

  &:first-of-type {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
    border-left: 0px;
  }
  &:nth-of-type(2) {
    position: sticky;
    left: ${(props) => props.columnStart}px;
    z-index: 1;
    background-color: white;
  }
  &:last-child {
    border-right: 0px;
  }

  .resizer {
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 3px;
    cursor: col-resize;
    user-select: none;
    touchaction: none;
    border-radius: 6px;
    background: #c39ad1;

    &.isResizing {
      background-color: #c39ad8;
      opacity: 1;
    }
  }
  &:hover > .resizer {
    opacity: 1;
  }
`;

export const TableCell = styled.td<{ columnWidth: number; columnStart: number }>`
  width: ${(props) => props.columnWidth}px;
  background-color: white;
  border-right: 1px solid #e9e9ee;
  border-bottom: 1px solid #e9e9ee;
  border-left: 1px solid #e9e9ee;
  padding-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:first-of-type {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
    border-left: 0px;
  }
  &:nth-of-type(2) {
    margin-left: 0;
    position: sticky;
    left: ${(props) => props.columnStart}px;
    z-index: 1;
    background-color: white;
  }
  &:last-child {
    border-right: 0px;
  }
`;

export const TableRow = styled.tr`
  cursor: pointer;

  &:hover td {
    background: #f7f3ff;
  }

  &.active {
    background: #f7f3ff;

    td {
      background: #f7f3ff;
    }
  }
`;
