import { Index } from "react-instantsearch";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { FeatureFlagNames, useFeatureFlags } from "hooks/useFeatureFlags";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import ActionButtons from "features/Tracking/components/ActionButtons";
import AlgoliaConfigure from "features/Tracking/components/AlgoliaConfigure/AlgoliaConfigure";
import ArchivedButton from "features/Tracking/components/AlgoliaArchivedButton";
import Search from "features/Tracking/components/Search";
import FilterDropdown from "features/CargoUnit/components/FilterDropdown";
import ExcelExportButton from "features/Tracking/components/ExcelExportButton.tsx";
import AlgoliaCargoUnitTable from "features/CargoUnit/components/AlgoliaCargoUnitTable";
import { ALGOLIA_INDEXES, IndexNameProvider } from "hooks/useIndexName";
import CurrentFilters from "components/CurrentFilters";
import CargoUnitCard from "features/CargoUnitCard";

// TODO @piercebb implement BackendCargoUnitTable when USE_BACKEND_RECORDS is true
const CargoUnitView = withAuthInfo(({ accessToken }: WithLoggedInAuthInfoProps) => {
  const { t } = useTranslation();
  const featureFlags = useFeatureFlags(accessToken);

  useDocumentTitle(t("Container"));

  return (
    <IndexNameProvider value={ALGOLIA_INDEXES.JOURNEY}>
      <Index indexName={ALGOLIA_INDEXES.JOURNEY}>
        <AlgoliaConfigure />
        <ActionButtons title="Container">
          <Search />
          <FilterDropdown />
          <ArchivedButton />
          <ExcelExportButton />
        </ActionButtons>
        <CurrentFilters />
        <AlgoliaCargoUnitTable />
        {featureFlags[FeatureFlagNames.EnableCargoUnitCardFrontend] && <CargoUnitCard />}
      </Index>
    </IndexNameProvider>
  );
});

export default CargoUnitView;
