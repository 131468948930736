import styled from "@emotion/styled";

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BasicTableInfo = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 1.07248px 5.36239px 0px rgba(0, 0, 0, 0.2);
  padding: 17px 21px;
  max-width: 100%;
  min-width: 340px;

  th {
    text-align: left;
    font-size: 10px;
    font-weight: 400;
    padding: 10px 0 0 15px;
  }

  tr {
    td {
      padding: 5px 15px 10px 15px;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 130px;
    }
  }
`;

export const ContainerContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .container-title {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    background: #eaecf0;
    border-radius: 16px 16px 0 0;

    img {
      width: 24px;
      height: 24px;
    }
  }

  ul.items {
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    border: 1px solid #e5e5e5;
    overflow-y: auto;
    max-height: 200px;

    li {
      padding: 5px 21px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .footer {
    height: 20px;
    background: #eaecf0;
    border-radius: 0 0 16px 16px;
  }
`;

export const Milestones = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  div.content {
    padding: 10px;
    border-radius: 25.739px;
    border: 1.072px solid #e9e9ee;
    background: #fff;
    box-shadow: 0px 1.07248px 5.36239px 0px rgba(0, 0, 0, 0.05);
    height: 120px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    .row {
      display: flex;
      overflow: auto;
      width: 100%;
      justify-content: center;
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .img {
        padding: 15px;
      }
    }

    .ata {
      width: 100%;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 600;
      .item.mileStoneStatus:after {
        border-bottom-color: #4cdbca;
      }
    }

    .eta {
      width: 100%;
      color: #868686;
    }

    .item {
      text-align: center;
    }

    .milestone {
      img {
        position: relative;
        z-index: 2;
      }

      .mileStoneStatus {
        position: relative;

        &:after {
          position: absolute;
          content: "";
          border-bottom: 6px solid #cdcdcd;
          width: 100%;
          top: 8px;
          left: 50%;
          z-index: 1;
        }
      }

      &:last-child {
        .mileStoneStatus:after {
          display: none;
        }
      }
    }
  }
`;
